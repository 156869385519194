import "./PageNotFound.css";
import React from "react";

function PageNotFound() {
  return (
    <div className="page-not-found">
      <h1>PageNotFound</h1>
    </div>
  );
}

export default PageNotFound;
